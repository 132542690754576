<template>
  <section class="main-wrapper">
    <div class="h-100 row">
      <div class="col-md-4 h-100 ad">
        <div class="mx-auto h-100 flex-column d-flex justify-content-between w-75">
          <div class="pt-3">
            <img src="@/assets/images/exxtra/exxtra-logo.png" width="70px" alt="exxtra-logo" />
          </div>

          <div class="">
            <ol class="position-relative border-left list-unstyled">
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center text-primary justify-content-center rounded-full stepper-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path d="M3.5 2A1.5 1.5 0 002 3.5V5c0 1.149.15 2.263.43 3.326a13.022 13.022 0 009.244 9.244c1.063.28 2.177.43 3.326.43h1.5a1.5 1.5 0 001.5-1.5v-1.148a1.5 1.5 0 00-1.175-1.465l-3.223-.716a1.5 1.5 0 00-1.767 1.052l-.267.933c-.117.41-.555.643-.95.48a11.542 11.542 0 01-6.254-6.254c-.163-.395.07-.833.48-.95l.933-.267a1.5 1.5 0 001.052-1.767l-.716-3.223A1.5 1.5 0 004.648 2H3.5zM16.5 4.56l-3.22 3.22a.75.75 0 11-1.06-1.06l3.22-3.22h-2.69a.75.75 0 010-1.5h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V4.56z" />
                  </svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Contact Info
                </h4>
                <p class="m-0 p-0 lead text-white">
                  Provide contact information
                </p>
              </li>
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                </span>
                <h1 class="m-0 p-0 secondary-heading">
                  Education Info
                </h1>
                <p class="m-0 p-0 text-white">
                  Provide information about your studies
                </p>
              </li>
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
                  </svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Success
                </h4>
                <p class="m-0 p-0 text-white">
                  You have successfully registered
                </p>
              </li>
            </ol>
          </div>
          <div>.</div>
          <div>.</div>
        </div>
      </div>
      <div class="col-md-8 h-100">
        <div class="h-100 flex-column d-flex overflow-auto align-items-center">
          <div class="pt-3 mx-auto w-half">
            <div class="mt-3 mb-2">
              <h1 class="m-0 p-0">
Tell Us More
</h1>
              <p>We would like to know more about your studies and plans overseas</p>
            </div>
            <b-overlay :show="loading">
              <validation-observer ref="registerGraduateForm" #default="{invalid}">
                <b-form @submit.prevent="onSubmit">
                  <b-form-group class="mb-2" label-size="lg" label="Have you received an admission offer from a university in USA or Canada ?" label-for="admission_offer">
                    <validation-provider #default="{ errors }" name="admission_offer" rules="required" vid="admission_offer">
                      <div class="exxtra-input">
                        <span class="leading">
                          <RegistrationMail />
                        </span>
                        <select id="admission_offer" v-model="info.admission_offer" name="register-email"
                               placeholder="brucewayne@exxtra.app"
                        >
                          <option value="received">
                            Yes, I have an offer
                          </option>
                          <option value="pending_admission">
                            Applied, pending admission
                          </option>
                          <option value="pending_application">
                            I'm yet to apply
                          </option>
                        </select>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group v-if="info.admission_offer === 'received'" class="mb-2" label-size="lg" label="Name of University ?" label-for="university">
                    <validation-provider #default="{ errors }" name="university" rules="required" vid="university">
                      <div class="exxtra-input">
                        <span class="leading">
                          <NearestLandmark />
                        </span>
                        <input id="university" v-model="info.university" type="text" name="university"
                               placeholder="University Of Ottawa"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Where did you complete your undergraduate studies ?" label-size="lg" label-for="undergraduate_country">
                    <validation-provider #default="{ errors }" name="undergraduate_country" rules="required" vid="undergraduate_country">
                      <div class="exxtra-input">
                        <span class="leading">
                          <GraduateCap />
                        </span>
                        <select id="undergraduate_country" v-model="info.undergraduate_country" name="undergraduate_country"
                               placeholder="brucewayne@exxtra.app"
                        >
                          <option value="Ghana">
                            Ghana 🇬🇭
                          </option>
                          <option value="Nigeria">
                            Nigeria 🇳🇬
                          </option>
                        </select>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group label="What postgraduate program would you be studying ?" label-size="lg" label-for="post_graduate_programme">
                    <validation-provider #default="{ errors }" name="post graduate programme" rules="required" vid="post_graduate_programme">
                      <div class="exxtra-input">
                        <span class="leading">
                          <Book />
                        </span>
                        <input id="post_graduate_programme" v-model="info.post_graduate_programme" type="text" name="post graduate program"
                               placeholder="Master of Data Science & Analytics"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group label="How much would you like to request ? ( Maximum $100,000)" label-size="lg" label-for="loan_amount">
                    <validation-provider #default="{ errors }" name="loan amount" rules="required" vid="loan_amount">
                      <div class="exxtra-input">
                        <span class="leading">
                          <Dollar />
                        </span>
                        <input id="loan_amount" v-model="info.loan_amount" type="number" max="100000" name="loan amount"
                               placeholder="100000"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Do you have visa to the country of the university ?" label-size="lg" label-for="undergraduate_country">
                    <validation-provider #default="{ errors }" name="undergraduate_country" rules="required" vid="undergraduate_country">
                      <div class="exxtra-input">
                        <span class="leading">
                          <Flag />
                        </span>
                        <select id="has_visa" v-model="info.has_visa" name="has_visa"
                                placeholder=""
                        >
                          <option value="true">
                            Yes, I have visa
                          </option>
                          <option value="false">
                            No, I'm yet to apply for visa
                          </option>
                        </select>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div class="d-flex mb-4 pb-2">
                    <b-button class="px-5 mr-auto border-primary border-2" variant="outline-primary" @click="$router.push({ name: 'gradloans-contact-update' })">
                      Previous
                    </b-button>
                    <b-button variant="primary" class="px-5" type="submit" :disabled="invalid">
                      Submit
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Book from "@/assets/svg/Book.svg";
import Flag from "@/assets/svg/Flag.svg";
import Dollar from "@/assets/svg/Dollar.svg";
import GraduateCap from "@/assets/svg/GraduateCap.svg";
import NearestLandmark from "@/assets/svg/NearestLandmark.svg";
import RegistrationMail from "@/assets/svg/RegistrationMail.svg";
import RegistrationPhone from "@/assets/svg/RegistrationPhone.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { get, has } from "lodash";
import { email, required } from '@validations';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BButton, BForm, BFormGroup, BFormInput, BOverlay } from "bootstrap-vue";

export default {
  name: "GraduateContact",
  components: {
    BButton,
    RegistrationMail,
    NearestLandmark,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    Book,
    Dollar,
    Flag,
    GraduateCap,
    RegistrationPhone,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      info: {
        admission_offer: '',
        undergraduate_country: '',
        post_graduate_programme: '',
        university: '',
        loan_amount: 0,
        has_visa: false
      },
      attempts: 3,
      graduate_applicant: '',
      captcha: Array.from({ length: 2 }, () => Math.floor(Math.random() * 40)),
      statusOptions: [
        { label: "Yes, I have an offer", value: "received" },
        { label: "I'm yet to apply", value: "pending_application" },
        { label: "Applied, pending admission", value: "pending_admission" }
      ],
      // validation rules
      required,
      email
    }
  },
  mounted() {
    this.restoreGraduateApplicant();
  },
  methods: {
    async onSubmit() {
      try {
        const success = await this.$refs.registerGraduateForm.validate();
        if (!success) {
          return;
        }

        this.generateCaptcha()


        const result = await this.$swal({
          title: "Solve human verification test ",
          text: `${this.captcha[0]} + ${this.captcha[1]}`,
          input: 'number',
          icon: "question",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Yes, proceed.',
          inputValidator: (value) => {
            return !value && 'Please enter number';
          },
          preConfirm: async (value) => {
            const sum = this.captcha.reduce((a, b) => a + b, 0)
            if (Number(sum) !== Number(value)) {
              if (+this.attempts === 1){
                await this.$router.push({ name: "gradloans" })
                this.$swal.close()
                return false;
              }
              this.attempts -= 1
              this.$swal.showValidationMessage(`Human verification failed,
               You have ${this.attempts} attempt(s) remaining`)
            }
          },
          customClass: {
            title: 'text-sm',
            input: 'w-100 mx-auto mb-2',
            htmlContainer: 'mb-2',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.isConfirmed) {
          return;
        }

        this.loading = true;
        const payload = { ...this.info, ...this.graduate_applicant }
        const response = await this.useJwt()
            .sharedService
            .registerGraduateApplicant(payload);
        const graduate_applicant = get(response, 'data.data');
        localStorage.setItem('graduate_applicant', JSON.stringify(graduate_applicant));
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `You have successfully signed up for exxtra graduate loans`,
          },
        });
        await this.$router.push({ name: 'gradloans-success' })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        // console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      } finally {
        this.loading = false
      }
    },
    generateCaptcha() {
      this.captcha = Array.from({ length: 2 }, () => Math.floor(Math.random() * 40));
    },
    async restoreGraduateApplicant() {
      const graduate_applicant = JSON.parse(localStorage.getItem('graduate_applicant'))
      if (!graduate_applicant) return this.$router.push({ name: "gradloans-contact" })

      this.graduate_applicant = graduate_applicant;

      const completed_stage_two = has(graduate_applicant, ['loan_amount'])

      if (completed_stage_two) return this.$router.push({ name: "gradloans-registered" })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/page-grad-applications.scss';

</style>
